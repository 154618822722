import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import Utils from '../../utils';
import CheckIcon from '../../assets/icons/simple-check.svg';

const Section = styled.section`
  padding-bottom: 100px;
`;

const Container = styled.div``;

const Title = styled.h2`
  text-align: center;
  font-size: 30px;
  color: ${$.color.base};
  margin-bottom: ${$.layout().margin4}px;
  line-height: 1.5em;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${$.layout().padding1}px;

  & > *:not(:last-child) {
    margin-bottom: ${$.layout().margin2}px;
  }

  ${$.device.mobile} {
    & > *:not(:last-child) {
      margin-bottom: ${$.layout().margin4}px;
    }
  }
`;

const Card = styled.div`
  color: ${$.color.base};
  font-size: 20px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;

  div {
    &:first-child {
      width: 10%;
      text-align: center;
      fill: ${$.color.red};
      svg {
        width: 30px;
        height: 30px;
      }
    }
    &:last-child {
      width: 90%;
    }
  }

  ${$.device.mobile} {
    flex-direction: column;
    div {
      width: 100%;
      text-align: center;
      &:first-child {
        margin-bottom: ${$.layout().margin1}px;
        svg {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
`;

const items = Utils.addKeys([
  {
    desc: 'To manufacture quality concrete products according to stringent recognized standards.',
  },
  {
    desc: 'To provide customer satisfaction in quality, cost & delivery.',
  },
  {
    desc: 'To continuously introduce environmental friendly in our processes.',
  },
  {
    desc: 'To provide conducive working environment to allow the personnel to fulfill their personal and professional satisfaction.',
  },
]);

const Mission = () => (
  <Section>
    <Container>
      <Fade bottom distance="100px">
        <Title>Our mission</Title>
      </Fade>
      <Cards>
        {items.map(({ key, desc }) => (
          <Fade bottom distance="100px" key={key}>
            <Card>
              <div>
                <CheckIcon />
              </div>
              <div>{desc}</div>
            </Card>
          </Fade>
        ))}
      </Cards>
    </Container>
  </Section>
);

export default Mission;
