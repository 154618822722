import React from 'react';
import styled from 'styled-components';
import SimpleReactLightbox from 'simple-react-lightbox';
import Fade from 'react-reveal/Fade';

import ImagePopup from './ImagePopup';
import $ from '../../../styles/global';

const Section = styled.section``;

const Container = styled.div``;

const Title = styled.div`
  color: ${$.color.base};
  font-size: 30px;
  text-align: center;
  font-family: Lato Regular;
  margin-bottom: ${$.layout().margin4}px;
`;

/**
 * I have to put the Lightbox in a separate component in order to use the lightbox
 * context.
 */
const OrganizationChart = () => (
  <Section>
    <Container>
      <Fade bottom distance="100px">
        <Title>Organization chart</Title>
        <SimpleReactLightbox>
          <ImagePopup />
        </SimpleReactLightbox>
      </Fade>
    </Container>
  </Section>
);

export default OrganizationChart;
