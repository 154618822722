import React from 'react';

import Seo from '../components/seo';
import Banner from '../components/AboutUs/Banner';
import Mission from '../components/AboutUs/Mission';
import Vision from '../components/AboutUs/Vision';
import Certificates from '../components/AboutUs/Certificates';
import CoreValues from '../components/AboutUs/CoreValues';
import OrganizationChart from '../components/AboutUs/OrganizationChart';

const AboutPage = () => (
  <>
    <Seo title="About Us | Zenn HRB" />
    <Banner />
    <Vision />
    <Mission />
    <CoreValues />
    <Certificates />
    <OrganizationChart />
  </>
);

export default AboutPage;
