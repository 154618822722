import React from 'react';
import styled from 'styled-components';
import { getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import { useStaticQuery, graphql } from 'gatsby';

import $ from '../../styles/global';

const Section = styled.section`
  padding-bottom: 100px;
`;

const Container = styled.div``;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100vw;
  position: relative;
  /* 17px is the scrollbar I think */
  left: calc(((100vw - 970px + 17px) / 2) * -1);

  ${$.device.tablet} {
    width: calc(100% + ${$.layout().margin3 * 2}px);
    left: -${$.layout().margin3}px;
  }

  ${$.device.mobile} {
    width: calc(100% + ${$.layout().margin3 * 2}px);
    left: -${$.layout().margin3}px;
  }
`;

const Content = styled.div`
  color: ${$.color.white};
  text-align: center;
  padding: 100px 0;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - ${$.layout().margin3 * 2}px);

  ${$.device.desktop} {
    width: 970px;
  }

  & h2 {
    font-size: 50px;
    font-family: Lato Bold;
    margin-bottom: ${$.layout().margin3}px;
  }

  & p {
    font-size: 18px;
  }

  ${$.device.tablet} {
    & h2 {
      font-size: 45px;
    }

    & p {
      font-size: 16px;
    }
  }

  ${$.device.mobile} {
    & h2 {
      font-size: 45px;
    }

    & p {
      font-size: 16px;
    }
  }
`;

const ValuesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${$.layout().padding4}px;

  ${$.device.mobile} {
    grid-template-columns: 1fr;
    grid-gap: ${$.layout().padding4}px;
  }
`;

const ValueContainer = styled.div``;

const Title = styled.div`
  font-family: Lato Bold;
  font-size: 24px;
  margin-bottom: ${$.layout().margin1}px;
  text-align: left;
`;

const Description = styled.div`
  text-align: left;
`;

const CoreValues = () => {
  const {
    file: { childImageSharp },
  } = useStaticQuery(graphql`
    {
      file(absolutePath: { regex: "/core-values-cover/" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);
  const bgImage = convertToBgImage(getImage(childImageSharp));

  return (
    <Section>
      <Container>
        <StyledBackgroundImage
          Tag="div"
          fluid={[
            'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
            bgImage.fluid,
          ]}
        >
          <Content>
            <h2>Our core values</h2>
            <ValuesContainer>
              <ValueContainer>
                <Title>Customer</Title>
                <Description>
                  We are committed to provide the highest level of service
                  possible to our customers.
                </Description>
              </ValueContainer>
              <ValueContainer>
                <Title>Quality</Title>
                <Description>
                  We value the quality of our products and services. We are
                  constantly seeking to improve our products to meet the
                  industry standards and beyond.
                </Description>
              </ValueContainer>
              <ValueContainer>
                <Title>Cost</Title>
                <Description>
                  We are constantly seeking to improve our products to provide
                  competitive prices while maintaining our industry standards.
                </Description>
              </ValueContainer>
              <ValueContainer>
                <Title>Delivery</Title>
                <Description>
                  Punctual delivery is our utmost priority. We constantly strive
                  to deliver our products to meet our customer&apos;s schedules.
                </Description>
              </ValueContainer>
            </ValuesContainer>
          </Content>
        </StyledBackgroundImage>
      </Container>
    </Section>
  );
};

export default CoreValues;
