import React from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import CarouselLightbox from '../../CarouselLightbox';
import images from './images';

const Section = styled.section``;

const Container = styled.div`
  text-align: center;
`;

const Certificates = () => (
  <Section>
    <Container>
      <Fade bottom distance="100px">
        <SimpleReactLightbox>
          <CarouselLightbox
            slidesToShow={3}
            images={images}
            title="Certificates"
          />
        </SimpleReactLightbox>
      </Fade>
    </Container>
  </Section>
);

export default Certificates;
