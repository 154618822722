import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import $ from '../../styles/global';

const Section = styled.section`
  padding-bottom: 100px;
`;

const Container = styled.div`
  position: relative;
  width: 100% !important;
  height: 600px;
  margin: 0 !important;

  & > * {
    width: 100%;
    height: 100%;
  }

  ${$.device.tablet} {
    width: 100%;
    height: 500px;
  }

  ${$.device.mobile} {
    width: 100%;
    height: 400px;
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  top: 0;
  left: 0;
`;

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  color: ${$.color.white};

  & h2 {
    font-size: 50px;
    font-family: Lato Bold;
    margin-bottom: ${$.layout().margin3}px;
  }

  & p {
    font-size: 18px;
  }

  ${$.device.desktop} {
    width: 970px;
  }

  ${$.device.tablet} {
    width: calc(100% - ${$.layout().margin3 * 2}px);
    & h2 {
      font-size: 45px;
    }

    & p {
      font-size: 16px;
    }
  }

  ${$.device.mobile} {
    width: calc(100% - ${$.layout().margin3 * 2}px);
    & h2 {
      font-size: 45px;
    }

    & p {
      font-size: 16px;
    }
  }
`;

const Vision = () => (
  <Section>
    <Container>
      <StaticImage src="../../assets/images/about-us/vision-cover.jpg" alt="" />
      <ImageOverlay>
        <Content>
          <h2>Our vision</h2>
          <p>
            To be the leading manufacturer of quality Prestressed Spun Concrete
            Poles and Concrete Products in Malaysia.
          </p>
        </Content>
      </ImageOverlay>
    </Container>
  </Section>
);

export default Vision;
