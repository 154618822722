import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { useLightbox } from 'simple-react-lightbox';

import Lightbox from '../../CarouselLightbox/Lightbox';
import Utils from '../../../utils';
import $ from '../../../styles/global';

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: ${$.layout().margin4}px;
  padding: 0 ${$.layout().padding1}px;
  width: calc(100% - ${$.layout().padding1 * 2}px);

  & > * {
    width: 100%;
    height: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`;

const images = Utils.addKeys([
  {
    src: Utils.importOneImage('about-us/organization_chart.png'),
    thumbnail: Utils.importOneImage('about-us/organization_chart.png'),
  },
]);

const ImagePopup = () => {
  const { openLightbox } = useLightbox();

  return (
    <>
      <ImageContainer
        title="Click to expand image"
        onClick={() => {
          openLightbox();
        }}
      >
        <StaticImage
          src="../../../assets/images/about-us/organization_chart.png"
          alt=""
        />
      </ImageContainer>
      <Lightbox images={images} />
    </>
  );
};

export default ImagePopup;
