import Utils from '../../../utils';

const images = Utils.addKeys([
  {
    src: Utils.importOneImage('about-us/cert-1-img.png'),
    thumbnail: Utils.importOneImage('about-us/cert-1-img.png'),
  },
  {
    src: Utils.importOneImage('about-us/cert-2-img.png'),
    thumbnail: Utils.importOneImage('about-us/cert-2-img.png'),
  },
  {
    src: Utils.importOneImage('about-us/cert-3-img.png'),
    thumbnail: Utils.importOneImage('about-us/cert-3-img.png'),
  },
  {
    src: Utils.importOneImage('about-us/cert-4-img.png'),
    thumbnail: Utils.importOneImage('about-us/cert-4-img.png'),
  },
  {
    src: Utils.importOneImage('about-us/cert-5-img.png'),
    thumbnail: Utils.importOneImage('about-us/cert-5-img.png'),
  },
  {
    src: Utils.importOneImage('about-us/cert-6-img.png'),
    thumbnail: Utils.importOneImage('about-us/cert-6-img.png'),
  },
  {
    src: Utils.importOneImage('about-us/cert-7-img.png'),
    thumbnail: Utils.importOneImage('about-us/cert-7-img.png'),
  },
  {
    src: Utils.importOneImage('about-us/cert-8-img.png'),
    thumbnail: Utils.importOneImage('about-us/cert-8-img.png'),
  },
]);

export default images;
