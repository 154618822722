import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';

const Section = styled.section`
  padding-bottom: 100px;
`;

const Container = styled.div`
  color: ${$.color.base};

  & p {
    padding: 0 ${$.layout().padding1}px;
    &:not(:last-child) {
      margin-bottom: ${$.layout().margin1}px;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: ${$.layout().margin4}px;

  & > * {
    width: 100%;
    height: 100%;
  }

  ${$.device.desktop} {
    width: 100%;
    height: 400px;
  }

  ${$.device.tablet} {
    width: calc(100% + ${$.layout().padding4 * 2}px);
    left: -${$.layout().padding4}px;
    height: 320px;
  }

  ${$.device.mobile} {
    width: calc(100% + ${$.layout().padding4 * 2}px);
    left: -${$.layout().padding4}px;
    height: 300px;
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;

  & > h1 {
    color: ${$.color.white};
    font-size: 50px;
    font-family: Lato Bold;
  }

  ${$.device.tablet} {
    & > h1 {
      font-size: 45px;
    }
  }

  ${$.device.mobile} {
    & > h1 {
      font-size: 40px;
    }
  }
`;

const Banner = () => (
  <Section>
    <Container>
      <ImageContainer>
        <StaticImage src="../../assets/images/about-us/banner.jpg" alt="" />
        <ImageOverlay>
          <h1>About Us</h1>
        </ImageOverlay>
      </ImageContainer>
      <Fade bottom distance="100px">
        <p>
          Zenn HRB is an ISO 9001-2008 certified company with industry expertise
          in concrete poles and products which dates back to 1985. We have since
          continued to build our foundation to better provide our customers with
          quality concrete products.
        </p>
        <p>
          With in-house facilities, we test every raw materials and finished
          product. This ensures that our product quality conform to the Japanese
          Industrial Standards (JIS A5309).
        </p>
        <p>
          We strive to meet the industry standards to better serve our
          customers.
        </p>
      </Fade>
    </Container>
  </Section>
);

export default Banner;
